import {createI18n, type Composer } from 'vue-i18n'

import en from './locales/en.json'
import {nextTick} from 'vue'

const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en
    },
    availableLocales: ['en']
})

export async function loadLocaleMessages(i18n : Composer, locale:string) {
    const messages = await import(`./locales/${locale}.json`)
    i18n.setLocaleMessage(locale, messages.default)
    return nextTick()
}

export default i18n
