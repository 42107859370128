<template>
    <div ref="jazzicon" />
</template>

<script setup lang="ts">
    import {onMounted, type PropType, ref} from 'vue'
    import MersenneTwister from 'mersenne-twister'

    const props = defineProps({
        seed: {
            type: Number,
            default: Math.round(Math.random() * 10000000)
        },
        diameter: {
            type: Number,
            default: 100,
            required: true
        },
        address: {
            type: String,
            default: '',
            required: true
        },
        shapeCount: {
            type: Number,
            default: 4
        },
        colors: {
            type: Array as PropType<string[]>,
            default: () => [
                '#01888C', // teal
                '#FC7500', // bright orange
                '#034F5D', // dark teal
                '#F73F01', // orangered
                '#FC1960', // magenta
                '#C7144C', // raspberry
                '#F3C100', // goldenrod
                '#1598F2', // lightning blue
                '#2465E1', // sail blue
                '#F19E02' // gold
            ]
        }
    })

    // data
    const jazzicon = ref()
    const svgns: string = 'http://www.w3.org/2000/svg'

    // methods
    const addressToNumber = (address: string): number => {
        return parseInt(address.slice(2, 10), 16)
    }

    const generator = new MersenneTwister(props.address ? addressToNumber(props.address) : props.seed)

    const newPaper = (diameter: number, color: string): { container: HTMLDivElement } => {
        const container = document.createElement('div')
        container.style.borderRadius = `${diameter / 2}px`
        container.style.overflow = 'hidden'
        container.style.padding = '0px'
        container.style.margin = '0px'
        container.style.width = `${diameter}px`
        container.style.height = `${diameter}px`
        container.style.display = 'inline-block'
        container.style.background = color
        return {
            container
        }
    }

    const genColor = (colors: string[]): string => {
        generator.random()
        const idx = Math.floor(colors.length * generator.random())
        return colors.splice(idx, 1)[0]
    }

    const genShape = (remainingColors: string[], diameter: number, i: number, total: number, svg: Element): void => {
        const center = diameter / 2
        const shape = document.createElementNS(svgns, 'rect')
        shape.setAttributeNS(null, 'x', '0')
        shape.setAttributeNS(null, 'y', '0')
        shape.setAttributeNS(null, 'width', diameter.toString())
        shape.setAttributeNS(null, 'height', diameter.toString())
        const firstRot = generator.random()
        const angle = Math.PI * 2 * firstRot
        const velocity = ((diameter / total) * generator.random()) + ((i * diameter) / total)
        const tx = Math.cos(angle) * velocity
        const ty = Math.sin(angle) * velocity
        const translate = `translate(${  tx  } ${  ty  })`
        // Third random is a shape rotation on top of all of that.
        const secondRot = generator.random()
        const rot = (firstRot * 360) + (secondRot * 180)
        const rotate = `rotate(${rot.toFixed(1)} ${center} ${center})`
        const transform = `${translate  } ${  rotate}`
        shape.setAttributeNS(null, 'transform', transform)
        const fill = genColor(remainingColors)
        shape.setAttributeNS(null, 'fill', fill)
        svg.appendChild(shape)
    }

    const hexToHSL = (hex: string): {h: number, s: number, l: number} => {
        // Convert hex to RGB first
        let r = parseInt(`0x${hex[1]}${hex[2]}`)
        let g = parseInt(`0x${hex[3]}${hex[4]}`)
        let b = parseInt(`0x${hex[5]}${hex[6]}`)
        // Then to HSL
        r /= 255
        g /= 255
        b /= 255
        const cmin = Math.min(r, g, b),
              cmax = Math.max(r, g, b),
              delta = cmax - cmin

        let h = 0,
            s = 0,
            l = 0

        if (delta === 0) h = 0
        else if (cmax === r) h = (((g - b) / delta)) % 6
        else if (cmax === g) h = ((b - r) / delta) + 2
        else h = ((r - g) / delta) + 4

        h = Math.round(h * 60)

        if (h < 0) h += 360

        l = (cmax + cmin) / 2
        s = delta === 0 ? 0 : delta / (1 - Math.abs((2 * l) - 1))
        s = +(s * 100).toFixed(1)
        l = +(l * 100).toFixed(1)

        return {h, s, l}
    }

    const HSLToHex = (hsl: {h: number, s: number, l: number}): string => {
        // eslint-disable-next-line prefer-const
        let {h, s, l} = hsl
        s /= 100
        l /= 100

        const c = (1 - Math.abs((2 * l) - 1)) * s,
              x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
              m = l - (c / 2)

        let r = 0,
            g = 0,
            b = 0

        if (0 <= h && h < 60) {
            r = c; g = x; b = 0
        } else if (60 <= h && h < 120) {
            r = x; g = c; b = 0
        } else if (120 <= h && h < 180) {
            r = 0; g = c; b = x
        } else if (180 <= h && h < 240) {
            r = 0; g = x; b = c
        } else if (240 <= h && h < 300) {
            r = x; g = 0; b = c
        } else if (300 <= h && h < 360) {
            r = c; g = 0; b = x
        }
        // Having obtained RGB, convert channels to hex
        let r1 = Math.round((r + m) * 255).toString(16)
        let g1 = Math.round((g + m) * 255).toString(16)
        let b1 = Math.round((b + m) * 255).toString(16)

        // Prepend 0s, if necessary
        if (r1.length === 1) r1 = `0${r1}`
        if (g1.length === 1) g1 = `0${g1}`
        if (b1.length === 1) b1 = `0${b1}`

        return `#${r1}${g1}${b1}`
    }

    const colorRotate = (hex: string, degrees: number): string => {
        const hsl = hexToHSL(hex)
        let hue = hsl.h
        hue = (hue + degrees) % 360
        hue = hue < 0 ? 360 + hue : hue
        hsl.h = hue
        return HSLToHex(hsl)
    }

    const hueShift = (colors: string[], generator: MersenneTwister): string[] => {
        const wobble = 30
        const amount = (generator.random() * 30) - (wobble / 2)
        return colors.map(x => colorRotate(x, amount))
    }

    const generateIdenticon = async(diameter: number): Promise<HTMLDivElement> => {
        const remainingColors = hueShift(props.colors.slice(), generator)
        const elements = newPaper(diameter, genColor(remainingColors))
        const container = elements.container
        const svg = document.createElementNS(svgns, 'svg')
        svg.setAttributeNS(null, 'x', '0')
        svg.setAttributeNS(null, 'y', '0')
        svg.setAttributeNS(null, 'width', diameter.toString())
        svg.setAttributeNS(null, 'height', diameter.toString())
        container.appendChild(svg)
        for (let i = 0; i < props.shapeCount - 1; i++) {
            genShape(remainingColors, diameter, i, props.shapeCount - 1, svg)
        }
        return container
    }

    const icon = async(): Promise<void> => {
        jazzicon.value.innerHTML = ''
        const el = await generateIdenticon(props.diameter)
        await jazzicon.value.append(el)
    }

    onMounted(async() => {
        await icon()
    })
</script>